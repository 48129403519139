import MainMasterInfo from "../MastersList/MasterItem/MainMasterInfo/MainMasterInfo";
import {Link, useParams} from "react-router-dom";
import ContentWrapper from "../../UI/ContentWrapper/ContentWrapper";
import MasterWork from "./MasterWork/MasterWork";
import {useQuery} from "@apollo/client";
import {BOOKING_TIMES, EMPLOYER} from "../../apollo/Queries";
import {apolloClientPosBranch} from "../../apollo/ApolloClients";
import {useEffect, useState} from "react";
import DateConvertToShow from "../../utils/workingWithDateTime/DateConvertToShow";
import ConvertDateForRequest from '../../utils/workingWithDateTime/ConvertDateFoRequest'
import {useDispatch, useSelector} from "react-redux";
import {setDate} from "../../store/dateReducer";
import MasterReviewsShort from "../Reviews/MasterReviews/MasterReviewsShort";
import Spinner from "../../components/Spinner/Spinner";
import {useTranslation} from "react-i18next";
import PickMasterComponent from "../../components/PickMasterComponent/PickMasterComponent";
import convertDateByTimezone from "../../utils/workingWithDateTime/convertDateByTimezone";
import './FoolMasterInfo.scss'
import AccordionComponentMorning from "../../components/AccordionComponent/AccordionComponentMorning";
import {day, morning, night, splitTimeSpans} from "../../utils/workingWithDateTime/splitTimeSpans";
import AccordionComponentDay from "../../components/AccordionComponent/AccordionComponentDay";
import AccordionComponentEvening from "../../components/AccordionComponent/AccordionComponentEvening";
import {NavigationComponent} from "../../components/NavigationComponent/NavigationComponent";
import {POS_URL} from "../../environment";

const FoolMasterInfo = () => {

    const {t: translate, i18n} = useTranslation();
    const currentLanguage = i18n.language;
    const {companyId, branchId, masterId} = useParams();
    const currentService = useSelector(state => state.serviceReducer.serviceId);
    const timeZone = useSelector(state => state.branchReducer.timeZone);
    const convertedDateByTimeZone = convertDateByTimezone(timeZone);
    const currentDate = useSelector(state => state.dateReducer.currentDate);
    const dateIsEntered = useSelector(state => state.dateReducer.dateIsEntered);
    const [morningIsOpen, setMorningIsOpen] = useState(false);
    const [dayIsOpen, setDayIsOpen] = useState(false);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);


    const [closestBookingDateTimes, setClosestBookingDateTimes] = useState([]);

    const {data: employerInfo} = useQuery(EMPLOYER(branchId, masterId, currentService), {
        client: apolloClientPosBranch, onCompleted: () => {
            setLoading(false)
        }
    });


    useEffect(() => {
        if (!dateIsEntered) {
            dispatch(setDate({currentDate: new Date(employerInfo?.employee.closestBookingDate), dateIsEntered: false}))
            setClosestBookingDateTimes(employerInfo?.employee?.closestBookingDateTimes)
        }

    }, [dateIsEntered, dispatch, employerInfo?.employee.closestBookingDate, employerInfo?.employee?.closestBookingDateTimes])
    ;
    const today = convertedDateByTimeZone;
    const dateToCheck = currentDate ? new Date(currentDate) : convertedDateByTimeZone;
    const isToday = dateToCheck.getDate() === today.getDate()
        && dateToCheck.getMonth() === today.getMonth()
        && dateToCheck.getFullYear() === today.getFullYear();

    const newListOfDates = (currentDate) => {
        apolloClientPosBranch.query({query: BOOKING_TIMES(ConvertDateForRequest(currentDate), branchId, masterId, currentService, true)}).then(({data}) => {
            setClosestBookingDateTimes(data.bookingTimes);
            setLoading(false);
        }).catch(error => {
            console.log(error)
        })
    }

    if (closestBookingDateTimes?.length !== 0) {
        splitTimeSpans(closestBookingDateTimes, timeZone)
    }

    const nextDayHandler = () => {
        setLoading(true);
        const tomorrow = new Date(currentDate);
        tomorrow.setDate(currentDate.getDate() + 1);
        dispatch(setDate({currentDate: new Date(tomorrow), dateIsEntered: false, timeIsPickedIn : null, pickedTime : null}));
        newListOfDates(tomorrow);
    }
    const prevDayHandler = () => {
        setLoading(true);
        const yesterday = new Date(currentDate);
        yesterday.setDate(currentDate.getDate() - 1);
        dispatch(setDate({currentDate: new Date(yesterday), dateIsEntered: false, timeIsPickedIn : null, pickedTime : null}))
        newListOfDates(yesterday);
    }

    return (
        <>
            <NavigationComponent
                navLink={`/company/${companyId}/branch-menu-list/${branchId}/masters`}
                linkText={translate('masterPage.backToMasterList')}
            />
            <div className="fool-master-inf">
                {(employerInfo && employerInfo.employee && !loading) ?
                    <>
                        <ContentWrapper>
                            <MainMasterInfo
                                name={employerInfo.employee.name}
                                surname={employerInfo.employee.surname}
                                position={employerInfo.employee.specialization}
                                masterKay={masterId}
                                photo={`${POS_URL}/${employerInfo.employee.image}`}
                                rating={employerInfo.employee.avgRating}
                                reviewCount={employerInfo.employee.reviewCount}
                                employeeId={employerInfo.employee.id}
                                employeeServiceRelation={employerInfo.employee.employeeServiceRelation}
                                textColor={'text-secondary'}
                                dontNeedBtnAbout={'yes'}/>
                            {employerInfo.employee.about &&
                                <p className="main_text_color my-3 fs-18">{employerInfo.employee.about}</p>
                            }
                            {<div className={'d-block d-md-none mb-4'}>
                                    <PickMasterComponent
                                        masterName={employerInfo.employee.name}
                                        employeeId={employerInfo.employee.id}
                                        masterPosition={employerInfo.employee.specialization}
                                        masterPhoto={`${POS_URL}/${employerInfo.employee.image}`}
                                        masterSurname={employerInfo.employee.surname}
                                        fullSizeBtn={'w-100'}
                                        employeeServiceRelation={employerInfo.employee.employeeServiceRelation}/>
                            </div>}
                            <hr/>
                            <MasterWork photoList={employerInfo.employee.qualificationConfirmations}/>

                            {!dateIsEntered &&
                                <>
                                    <div
                                        className="date-picker_wrapper mb-3 w-100 d-flex justify-content-center align-items-center">
                                        <button className="btn p-0 me-2 icon_to_block bg-white border-0" disabled={isToday}
                                                onClick={prevDayHandler}><i className="bi bi-chevron-left"></i></button>
                                        <p className="fw-bold fs-18 main_text_color mb-0">
                                            {DateConvertToShow(currentDate, currentLanguage)}
                                        </p>
                                        <button className="btn p-0 ms-2 icon_to_block" onClick={nextDayHandler}><i
                                            className="bi bi-chevron-right"></i></button>
                                    </div>
                                    <div className="accordion border-0" id="accordionExample">
                                        <div className={'mb-3'}>
                                            <AccordionComponentMorning
                                                morningIntervals={morning}
                                                accordionPosition={'morning'}
                                                currentDate={currentDate}
                                                morningIsOpen={setMorningIsOpen}
                                                convertedDateByTimeZone={convertedDateByTimeZone}
                                                foolInfoMasterStyles={'yes'}
                                                employeeId={employerInfo.employee.id}/>
                                        </div>

                                        <div className={'mb-3'}>
                                            <AccordionComponentDay
                                                dayIntervals={day}
                                                accordionPosition={'day'}
                                                currentDate={currentDate}
                                                dayIsOpen={setDayIsOpen}
                                                morningIsOpen={morningIsOpen}
                                                convertedDateByTimeZone={convertedDateByTimeZone}
                                                foolInfoMasterStyles={'yes'}
                                                employeeId={employerInfo.employee.id}/>
                                        </div>

                                        <div className={'mb-3'}>
                                            <AccordionComponentEvening
                                                nightIntervals={night}
                                                accordionPosition={'night'}
                                                currentDate={currentDate}
                                                dayIsOpen={dayIsOpen}
                                                morningIsOpen={morningIsOpen}
                                                convertedDateByTimeZone={convertedDateByTimeZone}
                                                foolInfoMasterStyles={'yes'}
                                                employeeId={employerInfo.employee.id}/>
                                        </div>
                                    </div>
                                </>
                            }

                            <MasterReviewsShort reviewsList={employerInfo.employee.lastReviews ? employerInfo.employee.lastReviews : []} reviewsCount={employerInfo.employee.reviewCount}/>
                            <div className={"row mt-4 pt-3"}>
                                <div className={"col-lg-6"}>
                                    <Link to={`/company/${companyId}/branch-menu-list/${branchId}/masters`}
                                          className="fw-normal btn btn-outline-primary w-100
                                          fs-7 icon_to_block rounded
                                          d-flex align-items-center justify-content-center mb-3 mb-lg-0">
                                        <i className="bi bi-chevron-left me-3"></i>
                                        {translate('masterPage.backToMasterList')}
                                    </Link>
                                </div>
                                <div className={"col-lg-6"}>
                                    <PickMasterComponent
                                        masterName={employerInfo.employee.name}
                                        employeeId={employerInfo.employee.id}
                                        masterPosition={employerInfo.employee.position.name}
                                        masterPhoto={employerInfo.employee.image}
                                        masterSurname={employerInfo.employee.surname}
                                        fullSizeBtn={'w-100'}
                                        employeeServiceRelation={employerInfo.employee.employeeServiceRelation}/>
                                </div>
                            </div>
                        </ContentWrapper>
                    </>
                    : <Spinner/>}
            </div>
        </>
    )
}

export default FoolMasterInfo;