import './MasterWork.scss'
import Fancybox from "../../../components/FancyboxWrapperComponent/FancyboxWrapperComponent";
import {POS_URL} from "../../../environment";

const MasterWork = ({photoList}) => {
    return (
        <div className="mb-3">
            {photoList.length !== 0 ?
                <Fancybox  options={{
                    Carousel: {
                        infinite: false,
                    },
                }}>
                    {photoList.map((photo, index) => (
                        <div className="col-3 col-lg-2" key={index}>
                            <div className="image-wrapper w-100 py-2 px-2 border rounded">
                                <a href={`${POS_URL}/${photo.image}`} data-fancybox="gallery">
                                    <img className={"img-fluid master-photo-size d-block w-100"} src={`${POS_URL}/${photo.image}`} alt=""/>
                                </a>
                            </div>
                        </div>
                    ))}
                </Fancybox>
                :
                null
            }
        </div>
    )
}

export default MasterWork