import {Routes, Route} from 'react-router-dom';
import 'react-phone-input-2/lib/style.css';
import './App.css';
import Header from "./components/Header/Header";
import BranchList from './pages/BranshList/BranchList';
import BranchMenuList from "./pages/BranchItem/BranchMenuList/BranchMenuList";
import AboutBranch from "./pages/AboutBranch/AboutBranch";
import MastersList from "./pages/MastersList/MastersList";
import FoolMasterInfo from "./pages/FoolMasterInfo/FoolMasterInfo";
import React from 'react';
import Services from "./pages/Services/Services";
import Reservation from "./pages/Reservation/Reservation";
import DateAndTime from "./pages/DateAndTime/DateAndTime";
import ConfirmedReservation from "./pages/ConfirmedReservation/ConfirmedReservation";
import CancelReservation from "./pages/CancelReservation/CancelReservation";
import Canceled from "./pages/CancelReservation/Canceled/Canceled";
import Reviews from "./pages/Reviews/Reviews";
import MasterReviews from "./pages/Reviews/MasterReviews/MasterReviews";
import ReviewAlreadyLeft from "./pages/Reviews/ReviewAlreadyLeft";
import PageNotFound from "./components/ErrorComponents/PageNotFound/PageNotFound";
import HrefToMePos from "./components/HrefToMePos/HrefToMePos";
import Advertising from "./components/Advertising/Advertising";
import { hotjar } from 'react-hotjar';


function App() {

    const HOTJAR_ID = 4953864;
    const HOTJAR_VERSION = 6;

    const options = {
        id : HOTJAR_ID,
        sv : HOTJAR_VERSION
    }

    hotjar.initialize(options);
    hotjar.event('button-click');

    return (
        <div className="App bg-light main d-flex flex-column">
            <Header/>
            <div className={"flex-grow-1 mb-5"}>
                <Routes>
                    <Route path='/' element={<HrefToMePos/>}/>
                    <Route path='/company/:companyId' element={<BranchList/>}/>
                    <Route path={'/company/:companyId/about-branch/:branchId/:from?'} element={<AboutBranch/>}/>
                    <Route path={'/company/:companyId/branch-menu-list/:branchId'} element={<BranchMenuList/>}/>
                    <Route path='/company/:companyId/branch-menu-list/:branchId/masters' element={<MastersList/>}/>
                    <Route path='/company/:companyId/branch-menu-list/:branchId/masters/:masterId'
                           element={<FoolMasterInfo/>}/>
                    <Route path="/company/:companyId/branch-menu-list/:branchId/services" element={<Services/>}/>
                    <Route path="/company/:companyId/branch-menu-list/:branchId/reservation" element={<Reservation/>}/>
                    <Route path="/company/:companyId/branch-menu-list/:branchId/set-date" element={<DateAndTime/>}/>
                    <Route path="/company/:companyId/branch-menu-list/:branchId/reservation/confirmed/:bookingId"
                           element={<ConfirmedReservation/>}/>
                    <Route path="/company/:companyId/branch-menu-list/:branchId/reservation/cancel/:bookingId"
                           element={<CancelReservation/>}/>
                    <Route path="/company/:companyId/branch-menu-list/:branchId/reservation/canceled/:bookingId"
                           element={<Canceled/>}/>
                    <Route path="/company/:companyId/reviews/:bookingId" element={<Reviews/>}/>
                    <Route path="/company/:companyId/branch-menu-list/:branchId/masters/:masterId/reviews" element={<MasterReviews/>}/>
                    <Route path={'/company/:companyId/reviews/:uuid/review-is-left'} element={<ReviewAlreadyLeft/>}/>
                    <Route path="*" element={<PageNotFound />} />
                </Routes>
            </div>
            <Advertising/>
          </div>
    );
}

export default App;
