import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";

const CurrentMaster = () => {
    const currentEmployee = useSelector(state => state.employeeReducer);
    const {companyId, branchId} = useParams();

    return (
        <div className="master-item__info d-flex align-items-center w-100">
            <div className="master-photo__wrapper rounded-circle me-4">
                <img className="d-block"
                     src={`${currentEmployee.employeePhoto}`}
                     alt="MasterPhoto"/>
            </div>
            <div className="master-item__name-position">
                <p className="text-secondary fs-18 mb-0">{currentEmployee.employeePosition}</p>
                <div className="d-flex align-items-start flex-column flex-md-row">
                    <div className="info-wrapper">
                        <a className="text-decoration-none d-flex align-items-center"
                           href={`/company/${companyId}/branch-menu-list/${branchId}/masters/${currentEmployee.employeeId}`}>
                            <p className="fw-bold main_text_color branch-name mb-0">{currentEmployee.employeeName} {currentEmployee.employeeSurname}</p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CurrentMaster