import ContentWrapper from "../../UI/ContentWrapper/ContentWrapper";
import Service from "./Service/Service";
import {useParams} from "react-router-dom";
import {useQuery} from "@apollo/client";
import {SERVICE_LIST} from "../../apollo/Queries";
import {apolloClientPosBranch} from "../../apollo/ApolloClients";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {convertDateTimeForRequest} from "../../utils/workingWithDateTime/convertDateTimeForRequest";
import Spinner from "../../components/Spinner/Spinner";
import {useTranslation} from "react-i18next";
import {setBookingValidate} from "../../store/bookingValidateReducer";
import convertDateByTimezone from "../../utils/workingWithDateTime/convertDateByTimezone";
import {NavigationComponent} from "../../components/NavigationComponent/NavigationComponent";


const Services = () => {

    const {t : translate} = useTranslation();
    const {companyId ,branchId} = useParams();
    const currentEmployer = useSelector(state => state.employeeReducer.employeeId);
    const currentDateTime = useSelector(state => state.dateReducer.currentDate);
    const dateIsEntered = useSelector(state => state.dateReducer.dateIsEntered);
    const timeZone = useSelector(state => state.branchReducer.timeZone);
    const convertedDateByTimeZone = convertDateByTimezone(timeZone);
    const dateForRequest = currentDateTime ? currentDateTime : convertedDateByTimeZone;
    const dispatch = useDispatch();
    const isBookingValid = useSelector(state => state.bookingValidateReducer.validate);
    const [loading, setLoading] = useState(true);

    const {data: branchServices} = useQuery(SERVICE_LIST,
        {
            client: apolloClientPosBranch,
            variables: {
                branchId: branchId,
                employeeId: currentEmployer,
                dateTime : dateIsEntered ? convertDateTimeForRequest(dateForRequest) : null,
                online : true
            }, onCompleted : () => {
                setLoading(false)
            }
        });
    const [filteredData, setFilteredData] = useState([]);
    const [input, setInput] = useState("");
    useEffect(() => {
        if (branchServices && branchServices?.services) {
            setFilteredData(branchServices?.services);
        }
        if (!isBookingValid) {
            dispatch(setBookingValidate({validate : true}))
        }
    }, [branchServices, dispatch, isBookingValid]);

    function handleChange(event) {
        setInput(event.target.value);
        if (event.target.value === '') {
            setFilteredData(branchServices.services)
        } else {
            const filtered = branchServices?.services.filter((item) =>
                item.serviceCategory?.name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                item.name.toLowerCase().includes(event.target.value.toLowerCase())
            );
            setFilteredData(filtered);
        }
    }

    return (
        <>
            <NavigationComponent
                navLink={`/company/${companyId}/branch-menu-list/${branchId}`}
                linkText={translate('backToMenu')}
            />
            <div className="service-list">
                <ContentWrapper>
                    <label htmlFor="searchInput">
                        <h5 className="main_text_color mb-3">
                            {translate('servicePage.services')}
                        </h5>
                    </label>
                    <div className="input-wrapper position-relative mb-3">
                        <input type="search" className="form-control custom-search-style" id="searchInput" value={input}
                               onChange={handleChange}/>
                        <button
                            className="border-0
                         bg-white icon_to_block
                         bg-transparent
                         position-absolute
                         end-0
                         top-50
                         translate-middle-y
                         me-2">
                            <i className="bi bi-search"></i>
                        </button>
                    </div>
                </ContentWrapper>
                {(filteredData && branchServices && branchServices.services.length !==0 && filteredData.length !==0) ?
                    <>
                        {
                            filteredData.map((serviceItem) => (
                                <ContentWrapper key={serviceItem.id}>
                                    <Service
                                        serviceCategory={serviceItem.name}
                                        serviceId={serviceItem.id}
                                        workPhoto={serviceItem.image}
                                        servicePrice={serviceItem.price}
                                        description={serviceItem.description}
                                        serviceTime={serviceItem.duration}
                                        currencyCode={serviceItem.currencyCode}
                                    />
                                </ContentWrapper>
                            ))
                        }
                    </>
                    : <p className={"mb-0 text-secondary text-center"}>
                        {translate('servicePage.servicesNotFound')}
                    </p>}
                {loading ? <Spinner/> : ''}
            </div>
        </>
    )
}

export default Services