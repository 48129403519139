import {convertDateTimeForRequest} from "../workingWithDateTime/convertDateTimeForRequest";

export const createBookingData = (formData, serviceId, employeeId, branchId, currentDate, companyReCall) => {


    const year = new Date(currentDate).getFullYear();
    const month = (new Date(currentDate).getMonth() + 1).toString().padStart(2, '0');
    const day = new Date(currentDate).getDate().toString().padStart(2, '0');

    const timeString = new Date(currentDate).toLocaleTimeString('en-US', { hour12: false });

    const formattedDate = `${year}-${month}-${day}`;

    let dayToSet = new Date(currentDate);
    let notificationSelect = null;

    switch (formData.notification) {
        case '1' : {
            const halfHour = dayToSet.setMinutes(dayToSet.getMinutes() - 30);
            if (new Date (halfHour).getTime() > new Date().getTime()) {
                notificationSelect = new Date(halfHour);
            }
            break
        }
        case '2' : {
            const oneHourAgo = dayToSet.setHours(dayToSet.getHours() - 1);
            notificationSelect = new Date(oneHourAgo);
            break
        }
        case '3' : {
            const oneHourAgo = dayToSet.setHours(dayToSet.getHours() - 2);
            notificationSelect = new Date(oneHourAgo);
            break
        }
        case '4' : {
            const oneHourAgo = dayToSet.setHours(dayToSet.getHours() - 3);
            notificationSelect = new Date(oneHourAgo);
            break
        }
        case '5' : {
            const oneDay = 24 * 60 * 60 * 1000;
            const previousDate = new Date(dayToSet.getTime() - oneDay);
            notificationSelect = new Date(previousDate);
            break
        }
        case '6' : {
            const oneDay = 24 * 60 * 60 * 1000;
            const previousDate = new Date(dayToSet.getTime() - (3 * oneDay));
            notificationSelect = new Date(previousDate);
            break
        }
        default : {
            notificationSelect = null
        }
    }

    return {
        date: `${formattedDate}`,
        time: `${timeString}`,
        notificationTime: (formData.isNeedNotification && notificationSelect) ? convertDateTimeForRequest(notificationSelect) : null,
        service: serviceId,
        employee: employeeId,
        branch: parseInt(branchId),
        notes: formData.userComment ? formData.userComment : null,
        isNeedClientRecall: companyReCall ? companyReCall : formData.isNeedClientRecall,
        client: {
            name: formData.userName,
            surname: formData.userSurname ? formData.userSurname : null,
            patronymic: formData.patronymic ? formData.patronymic : null,
            phone: formData.userPhone,
            email: formData.userEmail
        }
    }
}