import {gql } from '@apollo/client';

export const CREATE_BOOKING = gql`
  mutation CreateBooking($branchId : Int!,$input: BookingAppInput!) {
    createAppBooking(branchId : $branchId, input: $input) {
      id
      uuid
      status {
        id
        name
      }
      price
      notes
    }
  }
`;

export const CANCEL_BOOKING = gql`
  mutation CancelBooking($branchId : Int!,$id: Int!) {
    cancelAppBooking(branchId : $branchId ,id: $id)
  }
`;

export const ADD_REVIEW = gql`
  mutation AddReview($reviewInput: ReviewInput!, $bookingId: Int!) {
    leftReview(reviewInput: $reviewInput, bookingId: $bookingId)
  }
`;